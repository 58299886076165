<!--
 * @Description: 编辑规则弹窗
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-26 10:00:21
 * @LastEditors: 支磊
 * @LastEditTime: 2021-07-14 10:11:46
-->
<template>
  <div class="editRuleDialog">
    <div class="model"></div>
    <div class="main">
      <span class="close el-icon el-icon-close" @click="close"></span>
      <div class="title">编辑报警规则</div>
      <div class="form">
        <el-form ref="form" :rules="rules" :model="form" label-position="top">
          <div class="item" v-for="(item, index) in formData" :key="index">
            <el-form-item
              :prop="item2.rules"
              :label="item2.label"
              v-for="(item2, index2) in item.data"
              :key="index + 'item' + index2"
            >
              <el-input
                :disabled="item2.disabled"
                v-if="item2.type == 'input'"
                v-model="form[item2.formName]"
                @input="inputFNumber(item2)"
                size="small"
              ></el-input>

              <el-select
                style="width: 100%"
                size="small"
                v-if="item2.type == 'select'"
                v-model="form[item2.formName]"
                placeholder="请选择"
                :disabled="item2.disabled"
              >
                <el-option
                  v-for="item in thatData[item2.options]"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="foot">
        <el-button plain size="small" class="cancelBtn" @click="close"
          >取消</el-button
        >
        <el-button type="primary" plain size="small" @click="submintF"
          >提交</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { update, detail } from "@/api/rule.js";
export default {
  name: "editRuleDialog",
  components: {},
  props: {
    id: {},
  },
  data() {
    var isNotNull = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("不能为空"));
      } else {
        callback();
      }
    };
    var isHighQuote = (rule, value, callback) => {
      // 高报
      if (value === "") {
        callback(new Error("不能为空"));
      } else {
        if (this.form.rule) {
          if (this.form.rule == 1) {
            // 超限
            if (Number(this.form.highQuote) <= Number(this.form.lowQuote)) {
              callback(new Error("高报设置值须大于低报设置值"));
            } else {
              callback();
            }
          } else {
            // 低限
            if (Number(this.form.highQuote) >= Number(this.form.lowQuote)) {
              callback(new Error("高报设置值须低于低报设置值"));
            } else {
              callback();
            }
          }
        } else {
          callback();
        }
      }
    };
    var isHighHighQuote = (rule, value, callback) => {
      // 高报
      if (value === "") {
        callback(new Error("不能为空"));
      } else {
        if (this.form.rule) {
          if (this.form.rule == 1) {
            // 超限
            if (
              Number(this.form.highHighQuote) <= Number(this.form.highQuote)
            ) {
              callback(new Error("高高报设置值须大于高报设置值"));
            } else {
              callback();
            }
          } else {
            // 低限
            if (
              Number(this.form.highHighQuote) >= Number(this.form.highQuote)
            ) {
              callback(new Error("高高报设置值须低于高报设置值"));
            } else {
              callback();
            }
          }
        } else {
          callback();
        }
      }
    };
    return {
      rules: {
        name: [{ validator: isNotNull, trigger: "change", required: true }],
        axial: [{ validator: isNotNull, trigger: "change", required: true }], //轴向
        bandwidth: [
          { validator: isNotNull, trigger: "change", required: true },
        ], //带宽
        benchmark: [
          { validator: isNotNull, trigger: "change", required: true },
        ], //基准
        centerFrequency: [
          { validator: isNotNull, trigger: "change", required: true },
        ], //中心频率
        eigenvalue: [
          { validator: isNotNull, trigger: "change", required: true },
        ], //特征值
        highHighQuote: [
          {
            validator: isHighHighQuote,
            trigger: ["blur", "change"],
            required: true,
          },
        ], //高报
        highQuote: [
          {
            validator: isHighQuote,
            trigger: ["blur", "change"],
            required: true,
          },
        ], //高高报
        lowQuote: [
          { validator: isNotNull, trigger: ["blur", "change"], required: true },
        ], //低报
        rule: [{ validator: isNotNull, trigger: "change", required: true }], //报警规则
        sensorCategoryId: [
          { validator: isNotNull, trigger: "change", required: true },
        ], //传感器类型
        type: [{ validator: isNotNull, trigger: "change", required: true }], //报警类型
      },
      thatData: {},
      options: {},
      value: "",
      typeOptions: [
        //报警类型
        { label: "幅值", value: 1 },
        { label: "频谱", value: 2 },
        { label: "突变", value: 3 },
      ],
      sensorCategoryIdOptions: [
        // 传感器类型
        { label: "电流电压", value: 9 },
        { label: "电压", value: 8 },
        { label: "电流", value: 2 },
        { label: "振动", value: 1 },
      ],
      ruleOptions: [
        // 报警规则
        { label: "超限", value: 1 },
        { label: "低限", value: 2 },
      ],
      eigenvalueOptions: [
        // 特征值
        { label: "电流", value: 20, type: "2,9" },
        { label: "电压", value: 3, type: "8,9" },
        { label: "加速度峰值（m/s²）", value: 1, type: "1" },
        { label: "速度均方根值（mm/s）", value: 2, type: "1" },
        { label: "温度(℃)", value: 4, type: "1" },
      ],
      axialOptions: [
        { label: "x(a)", value: 1 },
        { label: "y(b)", value: 2 },
        { label: "z(c)", value: 3 },
      ],
      formData: [
        {
          data: [
            {
              label: "规则名称",
              type: "input",
              text: "data",
              formName: "name",
              disabled: false,
              rules: "name",
            },
            {
              label: "中心频率(Hz)",
              type: "input",
              text: "number",
              formName: "centerFrequency",
              disabled: false,
              rules: "centerFrequency",
            },
          ],
        },
        {
          data: [
            {
              label: "报警类型",
              type: "select",
              formName: "type",
              options: "typeOptions",
              disabled: false,
              rules: "type",
            },
            {
              label: "带宽(Hz)",
              type: "input",
              text: "number",
              formName: "bandwidth",
              disabled: false,
              rules: "bandwidth",
            },
          ],
        },
        {
          data: [
            {
              label: "传感器类型",
              type: "select",
              formName: "sensorCategoryId",
              options: "sensorCategoryIdOptions",
              disabled: false,
              rules: "sensorCategoryId",
            },
            {
              label: "基准",
              type: "input",
              text: "number",
              formName: "benchmark",
              disabled: false,
              rules: "benchmark",
            },
          ],
        },
        {
          data: [
            {
              label: "报警规则",
              type: "select",
              formName: "rule",
              options: "ruleOptions",
              disabled: false,
              rules: "rule",
            },
            {
              label: "低报",
              type: "input",
              text: "number",
              formName: "lowQuote",
              disabled: false,
              rules: "lowQuote",
            },
          ],
        },
        {
          data: [
            {
              label: "特征值",
              type: "select",
              formName: "eigenvalue",
              options: "eigenvalueOptions",
              disabled: false,
              rules: "eigenvalue",
            },
            {
              label: "高报",
              type: "input",
              text: "number",
              formName: "highQuote",
              disabled: false,
              rules: "highQuote",
            },
          ],
        },
        {
          data: [
            {
              label: "轴向",
              type: "select",
              formName: "axial",
              options: "axialOptions",
              disabled: false,
              rules: "axial",
            },
            {
              label: "高高报",
              type: "input",
              text: "number",
              formName: "highHighQuote",
              disabled: false,
              rules: "highHighQuote",
            },
          ],
        },
      ],
      form: {
        axial: "", //轴向
        bandwidth: "", //带宽
        benchmark: "", //基准
        centerFrequency: "", //中心频率
        eigenvalue: "", //特征值
        highHighQuote: "", //高报
        highQuote: "", //高高报
        id: "",
        lowQuote: "", //低报
        name: "",
        rule: "", //报警规则
        sensorCategoryId: "", //传感器类型
        type: "", //报警类型
      },
    };
  },

  computed: {},
  //监听执行
  watch: {
    "form.type": function () {
      //  中心频率
      let data = {
        ...this.formData[0].data[1],
      };
      // bandwidth 带宽
      let data2 = {
        ...this.formData[1].data[1],
      };

      // 特征值
      if (this.form.type == 2) {
        // 频谱
        data.disabled = false;
        data.rules = "centerFrequency";
        this.$set(this.formData[0].data, 1, data);
        data2.disabled = false;
        data2.rules = "bandwidth";
        this.$set(this.formData[1].data, 1, data2);
      } else {
        data.disabled = true;
        data.rules = "";
        this.$set(this.formData[0].data, 1, data);
        data2.disabled = true;
        data2.rules = "";
        this.$set(this.formData[1].data, 1, data2);
      }
      this.judgeSensorCategoryIdF();
    },
    "form.sensorCategoryId": function () {
      this.judgeSensorCategoryIdF();
      let id = this.form.sensorCategoryId;
      let data = this.eigenvalueOptions.filter((item) => {
        return item.type.indexOf(id) != -1;
      });
      this.$set(this.thatData, "eigenvalueOptions", data);
      if (this.form.eigenvalue) {
        let item = data[0].value;
        for (let i = 0; i < data.length; i++) {
          if (data[i].value == this.form.eigenvalue) {
            item = data[i].value;
          }
        }
        this.$set(this.form, "eigenvalue", item);
      }
    },
  },
  mounted() {
    this.init();
    this.getDetailF();
  },
  methods: {
    getDetailF() {
      detail(this.id).then((res) => {
        if (this.apiReply(res)) {
          this.form = {
            ...res.data.data.ruleInfo,
          };
        }
      });
    },
    inputFNumber(data) {
      //验证数字
      if (data.text == "number") {
        let number = this.form[data.formName];
        let reg = /^[0-9]+\.?[0-9]*$/;
        if (!reg.test(number)) {
          number = parseFloat(number);
        }
        this.$set(this.form, data.formName, number);
      }
    },
    init() {
      this.thatData = {
        typeOptions: this.typeOptions,
        sensorCategoryIdOptions: this.sensorCategoryIdOptions,
        ruleOptions: this.ruleOptions,
        eigenvalueOptions: this.eigenvalueOptions,
        axialOptions: this.axialOptions,
      };
    },
    judgeSensorCategoryIdF() {
      // 处理特征值状态
      let data = {
        ...this.formData[4].data[0],
      };
      if (this.form.sensorCategoryId == 1 && this.form.type == 2) {
        data.disabled = true;
        data.rules = "";
        this.form.eigenvalue = "";
        this.$set(this.formData[4].data, 0, data);
      } else {
        data.disabled = false;
        data.rules = "eigenvalue";
        this.$set(this.formData[4].data, 0, data);
      }
    },
    submintF() {
      // 提交
      // create
      this.$refs.form.validate((valid) => {
        console.log(valid);
        if (valid) {
          let form = {
            ...this.form,
          };
          if (!form.bandwidth) {
            form.bandwidth = "";
          }
          if (!form.centerFrequency) {
            form.centerFrequency = "";
          }
          delete form.categoryName;
          form.id = this.id;
          update(form).then((res) => {
            if (this.apiReply(res)) {
              this.$message({
                type: "success",
                message: "编辑成功!",
              });
              this.close("update");
            }
          });
        }
      });
    },
    close(data) {
      this.$emit("close", data);
    },
  },
};
</script>
<style lang="scss" scoped>
.editRuleDialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  .model {
    position: absolute;
    @extend .model;
  }
  .main {
    width: 700px;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    background: #ffffff;
    border-radius: 10px;
    padding: 28px;
    .close {
      color: #909399;
      font-size: 24px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      z-index: 9;
    }
    .title {
      @extend .title_green;
    }
    .form {
      padding-top: 20px;
      .item {
        display: flex;
        justify-content: space-between;
        .el-form-item {
          width: 48%;
        }
        /deep/.el-form-item__label {
          line-height: 1;
          text-align: left;
          width: 100%;
        }
      }
    }
    .foot {
      text-align: right;
    }
  }
}
</style>
