<!--
 * @Description: 规则列表页面
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-25 09:06:10
 * @LastEditors: 支磊
 * @LastEditTime: 2021-09-27 10:03:05
-->
<template>
  <div class="ruleList">
    <div class="select">
      <el-input
        style="width: 450px"
        placeholder="请输入内容"
        prefix-icon="el-icon-search"
        size="small"
        v-model="searchString"
      >
      </el-input>
      <AddNewBtn @add="addF" />
    </div>
    <div class="table">
      <el-table :data="tableData" style="width: 100%" height="100%">
        <el-table-column prop="name" label="规则名称" width="180">
        </el-table-column>
        <el-table-column align="center" label="报警类型" width="180">
          <template slot-scope="scope">
            {{ scope.row.rule | ruleType }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="categoryName" label="传感器类型">
        </el-table-column>
        <el-table-column align="center" prop="type" label="报警规则">
          <template slot-scope="scope">
            {{ scope.row.type == 1 ? "超限" : "低限" }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="特征值">
          <template slot-scope="scope">
            {{ scope.row.eigenvalue | eigen }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="centerFrequency" label="中心频率">
        </el-table-column>
        <el-table-column align="center" prop="axial" label="轴向">
          <template slot-scope="scope">
            {{ axial[scope.row.axial] }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="benchmark" label="基准">
        </el-table-column>
        <el-table-column align="center" prop="lowQuote" label="低报">
        </el-table-column>
        <el-table-column align="center" prop="highQuote" label="高报">
        </el-table-column
        ><el-table-column align="center" prop="highHighQuote" label="高高报">
        </el-table-column
        ><el-table-column
          align="center"
          prop="address"
          width="120px"
          label="操作"
        >
          <template slot-scope="scope">
            <i class="el-icon-edit edit" @click="editF(scope.row)"></i>
            <i class="el-icon-delete delete" @click="deleteF(scope.row)"></i>
          </template>
        </el-table-column>
        <template slot="empty">
          <div>
            <img style="width: 96px" src="/img/notData.png" alt="" />
            <p style="color: #909399; font-size: 14px; text-align: center">
              暂无数据
            </p>
          </div>
        </template>
      </el-table>
    </div>
    <div class="page">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 添加规则 -->
    <addDialog v-if="dialogShow" @close="closeDailogF" />
    <!-- 添加规则 end -->
    <!-- 编辑规则 -->
    <editDialog v-if="dialogEditShow" @close="closeEditDailogF" :id="ruleId" />
    <!-- 编辑规则 end -->
  </div>
</template>

<script>
import addDialog from "./private/addRuleDialog";
import editDialog from "./private/editRuleDialog";

import { list, deleteApi } from "@/api/rule.js";
export default {
  name: "ruleList",
  components: { addDialog, editDialog },
  data() {
    return {
      ruleId: "",
      dialogEditShow: false,
      axial: [0, "x(A)", "y(B)", "z(C)"],
      searchString: "",
      pageSize: 10,
      currentPage: 1,
      tableData: [],
      total: 0,
      timeF: null,
      dialogShow: false,
    };
  },
  filters: {
    ruleType(data) {
      let text = "幅值";
      switch (data) {
        case 1:
          text = "幅值";
          break;
        case 2:
          text = "频谱";
          break;
        case 3:
          text = "突变";
          break;
        default:
          break;
      }
      return text;
    },
    eigen(data) {
      let text = "加速度峰值";
      switch (data) {
        case 1:
          text = "加速度峰值";
          break;
        case 2:
          text = "速度均方根";
          break;
        case 3:
          text = "电压";
          break;
        case 4:
          text = "温度";
          break;
        default:
          break;
      }
      return text;
    },
  },
  computed: {},
  //监听执行
  watch: {
    searchString() {
      clearTimeout(this.timeF);
      this.timeF = setTimeout(() => {
        this.currentPage = 1;
        this.getData();
      }, 1200);
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    editF(data) {
      this.ruleId = data.id;
      this.dialogEditShow = true;
    },
    closeDailogF(data) {
      // 关闭新建弹窗
      this.dialogShow = false;
      if (data && data == "update") {
        this.getData();
      }
    },
    closeEditDailogF(data) {
      // 关闭新建弹窗
      this.dialogEditShow = false;
      if (data && data == "update") {
        this.getData();
      }
    },
    deleteF(data) {
      console.log("删除", data);
      this.$confirm("此操作将永久删除该规则, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteApi({ ids: [data.id] }).then((res) => {
            if (this.apiReply(res)) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getData();
            }
          });
        })
        .catch(() => {});
    },
    addF() {
      // 打开新建弹窗
      this.dialogShow = true;
    },
    getData() {
      this.tableData = [];
      let data = {
        searchString: this.searchString,
        pageNo: this.currentPage,
        pageSize: this.pageSize,
      };
      list(data).then((res) => {
        if (this.apiReply(res)) {
          this.tableData = this.tableData.concat(res.data.data.dataList);
          console.log(this.tableData);
          this.total = res.data.data.total;
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
  },
};
</script>
<style lang="scss" scoped>
.ruleList {
  height: 100%;
  padding: 20px 44px 24px;
  .select {
    display: flex;
    justify-content: space-between;
  }
  .table {
    height: calc(100% - 92px);
    overflow: auto;
    padding-top: 10px;
  }
  .page {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .edit {
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      color: $main_color;
    }
  }
  .delete {
    cursor: pointer;
    color: $delete_color;
  }
}
</style>
